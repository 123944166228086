import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, Trash2, ArrowLeft, MessageSquare } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from 'axios';
import PersonalizedCategorySlider from '../components/PersonalizedCategorySlider';
import FavoriteItem from '../components/FavoriteItem';
import FavoritesSkeleton from '../components/FavoritesSkeleton';

const API_BASE_URL = 'https://findout-adf55aa841e8.herokuapp.com';

const Favorites = () => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [displayedTitle, setDisplayedTitle] = useState('');
  const [token, setToken] = useState(null);
  const [activeFavoriteId, setActiveFavoriteId] = useState(null);

  // Verificar si el usuario está autenticado
  useEffect(() => {
    const userToken = localStorage.getItem('token');
    if (!userToken) {
      navigate('/login', { state: { returnUrl: '/favorites' } });
      return;
    }
    setToken(userToken);
  }, [navigate]);

  // Cargar los favoritos del usuario
  useEffect(() => {
    const fetchFavorites = async () => {
      if (!token) return;
      
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/analytics/favorites/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        setFavorites(response.data);
        setError(null);
      } catch (err) {
        console.error('Error al cargar favoritos:', err);
        setError('No pudimos cargar tus favoritos. Por favor, intenta de nuevo más tarde.');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchFavorites();
    }
  }, [token]);

  // Efecto de escritura para el título
  useEffect(() => {
    const title = 'Mis Favoritos';
    let index = 0;
    const interval = setInterval(() => {
      if (index <= title.length) {
        setDisplayedTitle(title.slice(0, index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  // Filtrar favoritos según la pestaña activa
  const filteredFavorites = favorites.filter(favorite => {
    if (activeTab === 'all') return true;
    if (activeTab === 'products') return favorite.product !== null;
    if (activeTab === 'companies') return favorite.company !== null;
    if (activeTab === 'categories') return favorite.category !== null;
    return true;
  });

  // Eliminar un favorito
  const handleRemoveFavorite = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/analytics/favorites/${id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setFavorites(favorites.filter(fav => fav.id !== id));
    } catch (err) {
      console.error('Error al eliminar favorito:', err);
      setError('No pudimos eliminar este favorito. Por favor, intenta de nuevo más tarde.');
    }
  };

  // Editar notas de un favorito
  const handleUpdateNotes = async (id, notes) => {
    try {
      await axios.patch(
        `${API_BASE_URL}/api/analytics/favorites/${id}/`, 
        { notes }, 
        {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setFavorites(favorites.map(fav => 
        fav.id === id ? { ...fav, notes } : fav
      ));
    } catch (err) {
      console.error('Error al actualizar notas:', err);
      setError('No pudimos actualizar las notas. Por favor, intenta de nuevo más tarde.');
    }
  };

  if (loading) {
    return <FavoritesSkeleton />;
  }

  return (
    <div className="min-h-screen pb-16">
      {/* Header con gradiente */}
      <div className="relative w-full h-40 bg-gradient-to-r from-primary to-primary-dark">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative z-10 h-full flex flex-col justify-end p-4">
          <button 
            onClick={() => navigate(-1)} 
            className="absolute top-4 left-4 text-white p-1 rounded-full bg-black/20"
          >
            <ArrowLeft size={24} />
          </button>
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/10 backdrop-blur-sm rounded-full">
              <Heart size={24} className="text-white" />
            </div>
            <div>
              <motion.h1 
                className="text-2xl font-bold leading-[1rem] text-white"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {displayedTitle}
              </motion.h1>
              <motion.p 
                className="text-base text-white/90"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                Guarda tus productos y negocios favoritos
              </motion.p>
            </div>
          </div>
        </div>
      </div>

      {/* PersonalizedCategorySlider con márgenes */}
      <div className="mx-4 mt-4">
        <PersonalizedCategorySlider />
      </div>

      {/* Tabs para filtrar favoritos */}
      <div className="flex justify-between px-4 mt-6 mb-4 border-b border-gray-200 dark:border-gray-700">
        <button 
          className={`pb-2 px-1 text-sm font-medium ${activeTab === 'all' ? 'text-primary border-b-2 border-primary' : 'text-gray-500 dark:text-gray-400'}`}
          onClick={() => setActiveTab('all')}
        >
          Todos
        </button>
        <button 
          className={`pb-2 px-1 text-sm font-medium ${activeTab === 'products' ? 'text-primary border-b-2 border-primary' : 'text-gray-500 dark:text-gray-400'}`}
          onClick={() => setActiveTab('products')}
        >
          Productos
        </button>
        <button 
          className={`pb-2 px-1 text-sm font-medium ${activeTab === 'companies' ? 'text-primary border-b-2 border-primary' : 'text-gray-500 dark:text-gray-400'}`}
          onClick={() => setActiveTab('companies')}
        >
          Negocios
        </button>
        <button 
          className={`pb-2 px-1 text-sm font-medium ${activeTab === 'categories' ? 'text-primary border-b-2 border-primary' : 'text-gray-500 dark:text-gray-400'}`}
          onClick={() => setActiveTab('categories')}
        >
          Categorías
        </button>
      </div>

      {/* Mensaje de error */}
      {error && (
        <div className="bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-200 p-4 rounded-lg mx-4 mb-4">
          {error}
        </div>
      )}

      {/* Lista de favoritos */}
      <div className="px-4">
        {filteredFavorites.length > 0 ? (
          <div className="space-y-4">
            {filteredFavorites.map(favorite => (
              <FavoriteItem 
                key={favorite.id} 
                favorite={favorite} 
                onRemove={handleRemoveFavorite}
                onUpdateNotes={handleUpdateNotes}
                isActive={activeFavoriteId === favorite.id}
                onActivate={setActiveFavoriteId}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-12 text-center">
            <Heart size={48} className="text-gray-300 dark:text-gray-600 mb-4" />
            <h3 className="text-xl font-medium text-gray-700 dark:text-gray-300 mb-2">
              No tienes favoritos {activeTab !== 'all' ? `en ${activeTab}` : ''}
            </h3>
            <p className="text-gray-500 dark:text-gray-400 max-w-xs">
              Explora productos y negocios y guárdalos para verlos más tarde
            </p>
            <button 
              onClick={() => navigate('/')}
              className="mt-6 px-6 py-2 border-2 border-primary dark:border-primary-dark hover:bg-primary-dark text-primary dark:text-primary-dark rounded-full transition-colors"
            >
              Explorar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Favorites;
