import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const API_URL = 'https://findout-adf55aa841e8.herokuapp.com';
  
  // Capturar la URL anterior cuando el componente se monta
  useEffect(() => {
    // Obtener la URL anterior de state o usar '/' como valor predeterminado
    const from = location.state?.from || '/';
    // Guardar la URL anterior en localStorage
    localStorage.setItem('redirectAfterLogin', from);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/login/`,
        { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        }
      );

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify({
          id: response.data.user_id,
          username: response.data.username,
          email: response.data.email
        }));
        
        // Si "recordarme" está activado, podríamos guardar información adicional
        if (rememberMe) {
          localStorage.setItem('rememberedUser', username);
        } else {
          localStorage.removeItem('rememberedUser');
        }
        
        axios.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;
        
        // Obtener la URL de redirección guardada
        const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
        // Limpiar la URL guardada
        localStorage.removeItem('redirectAfterLogin');
        // Redirigir al usuario a la URL anterior
        navigate(redirectUrl);
      }
    } catch (error) {
      let errorMessage = 'Error durante el inicio de sesión';
      if (error.response) {
        errorMessage = error.response.data.error || 'Error en las credenciales';
        if (error.response.status === 403) {
          errorMessage = 'Acceso denegado. Por favor, verifica tus credenciales.';
        }
      } else if (error.request) {
        errorMessage = 'No se pudo conectar con el servidor';
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
      <div className="w-full max-w-md px-4">
        <form className="form" onSubmit={handleSubmit}>
          <div className="flex-column">
            <label className="text-gray-900 dark:text-white">Usuario</label>
          </div>
          <div className="inputForm dark:border-gray-700 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 32 32" height="20" className="text-gray-400 dark:text-gray-300">
              <g data-name="Layer 3" id="Layer_3">
                <path d="m30.853 13.87a15 15 0 0 0 -29.729 4.082 15.1 15.1 0 0 0 12.876 12.918 15.6 15.6 0 0 0 2.016.13 14.85 14.85 0 0 0 7.715-2.145 1 1 0 1 0 -1.031-1.711 13.007 13.007 0 1 1 5.458-6.529 2.149 2.149 0 0 1 -4.158-.759v-10.856a1 1 0 0 0 -2 0v1.726a8 8 0 1 0 .2 10.325 4.135 4.135 0 0 0 7.83.274 15.2 15.2 0 0 0 .823-7.455zm-14.853 8.13a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6z"/>
              </g>
            </svg>
            <input
              type="text"
              className="input dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
              placeholder="Ingresa tu usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>

          <div className="flex-column">
            <label className="text-gray-900 dark:text-white">Contraseña</label>
          </div>
          <div className="inputForm dark:border-gray-700 dark:bg-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="-64 0 512 512" height="20" className="text-gray-400 dark:text-gray-300">
              <path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"/>
              <path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"/>
            </svg>
            <input
              type="password"
              className="input dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>

          {error && (
            <div className="text-red-500 text-sm mt-2">
              {error}
            </div>
          )}

          <div className="flex-row">
            <div className="custom-checkbox">
              <input 
                type="checkbox" 
                id="remember" 
                className="hidden" 
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="remember" className="checkbox-label">
                <span className="checkbox-custom"></span>
                <span className="text-gray-900 dark:text-white ml-2">Recordarme</span>
              </label>
            </div>
            <span className="span dark:text-primary">¿Olvidaste tu contraseña?</span>
          </div>

          <button 
            className="button-submit dark:border-primary dark:text-primary dark:hover:bg-primary dark:hover:text-white"
            disabled={isLoading}  
          >
            <span className="flex items-center justify-center">
              {isLoading ? (
                'Iniciando sesión...'
              ) : (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                    <polyline points="10 17 15 12 10 7"></polyline>
                    <line x1="15" y1="12" x2="3" y2="12"></line>
                  </svg>
                  Iniciar Sesión
                </>
              )}
            </span>
          </button>

          <p className="p dark:text-white">
            ¿No tienes una cuenta? 
            <Link to="/register" className="span dark:text-primary">Regístrate</Link>
          </p>
        </form>
      </div>

      <style jsx>{`
        .form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          background-color: #ffffff;
          padding: 25px;
          width: 100%;
          border-radius: 20px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          margin: 0 auto;
          margin-top: -100px; /* Mueve el formulario hacia arriba */
        }

        .dark .form {
          background-color: #1f2937;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
        }

        .flex-column > label {
          color: #151717;
          font-weight: 600;
        }

        .dark .flex-column > label {
          color: #ffffff;
        }

        .inputForm {
          border: 1.5px solid #ecedec;
          border-radius: 10px;
          height: 50px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          transition: 0.2s ease-in-out;
        }

        .dark .inputForm {
          border: 1.5px solid #374151;
        }

        .input {
          margin-left: 10px;
          border-radius: 10px;
          border: none;
          width: 100%;
          height: 100%;
        }

        .input:focus {
          outline: none;
        }

        .inputForm:focus-within {
          border: 1.5px solid #2d79f3;
        }

        .dark .inputForm:focus-within {
          border: 1.5px solid #09fdfd;
        }

        .flex-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
        }

        .custom-checkbox {
          display: flex;
          align-items: center;
        }

        .checkbox-label {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
        }

        .checkbox-custom {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #2d79f3;
          border-radius: 4px;
          background-color: transparent;
          transition: all 0.2s ease;
        }

        .dark .checkbox-custom {
          border-color: #09fdfd;
        }

        input[type="checkbox"]:checked + .checkbox-label .checkbox-custom::after {
          content: '';
          position: absolute;
          top: 2px;
          left: 5px;
          width: 6px;
          height: 10px;
          border: solid #2d79f3;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        .dark input[type="checkbox"]:checked + .checkbox-label .checkbox-custom::after {
          border-color: #09fdfd;
        }

        .span {
          font-size: 14px;
          margin-left: 5px;
          color: #2d79f3;
          font-weight: 500;
          cursor: pointer;
        }

        .dark .span {
          color: #09fdfd;
        }

        .button-submit {
          margin: 20px 0 10px 0;
          background-color: transparent;
          border: 2px solid #2d79f3;
          color: #2d79f3;
          font-size: 15px;
          font-weight: 500;
          border-radius: 10px;
          height: 50px;
          width: 100%;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .button-submit:hover {
          background-color: #2d79f3;
          color: white;
        }

        .dark .button-submit {
          border-color: #09fdfd;
          color: #09fdfd;
        }

        .dark .button-submit:hover {
          background-color: #09fdfd;
          color: #0f172a;
        }

        .button-submit:disabled {
          background-color: transparent;
          border-color: #cccccc;
          color: #cccccc;
          cursor: not-allowed;
        }

        .dark .button-submit:disabled {
          border-color: #4b5563;
          color: #4b5563;
        }

        .p {
          text-align: center;
          color: black;
          font-size: 14px;
          margin: 5px 0;
        }

        .dark .p {
          color: white;
        }

        @media (max-height: 700px) {
          .form {
            margin-top: -50px;
            padding: 20px;
          }
        }

        @media (max-height: 600px) {
          .form {
            margin-top: 0;
            padding: 15px;
          }
        }
      `}</style>
    </div>
  );
};

export default Login;