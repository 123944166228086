import React from 'react';

const FavoritesSkeleton = () => {
  return (
    <div className="min-h-screen pb-16">
      {/* Header Skeleton */}
      <div className="relative w-full h-40 bg-gradient-to-r from-primary to-primary-dark animate-pulse">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative z-10 h-full flex flex-col justify-end p-4">
          <div className="absolute top-4 left-4 w-10 h-10 rounded-full bg-white/20"></div>
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white/10 rounded-full w-10 h-10"></div>
            <div>
              <div className="h-6 w-32 bg-white/20 rounded mb-2"></div>
              <div className="h-4 w-48 bg-white/20 rounded"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Category Slider Skeleton */}
      <div className="mx-4 mt-4">
        <div className="flex space-x-2 overflow-x-auto py-2">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="flex-shrink-0 w-24 h-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
          ))}
        </div>
      </div>

      {/* Tabs Skeleton */}
      <div className="flex justify-between px-4 mt-6 mb-4 border-b border-gray-200 dark:border-gray-700">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="pb-2 px-1 w-16 h-6 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
        ))}
      </div>

      {/* Favorites List Skeleton */}
      <div className="px-4 space-y-4">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4 animate-pulse">
            <div className="flex justify-between items-start mb-2">
              <div className="flex items-center">
                <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full mr-2"></div>
                <div>
                  <div className="h-5 w-32 bg-gray-200 dark:bg-gray-700 rounded mb-1"></div>
                  <div className="h-3 w-24 bg-gray-200 dark:bg-gray-700 rounded"></div>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
              </div>
            </div>
            <div className="mt-2 h-16 bg-gray-200 dark:bg-gray-700 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FavoritesSkeleton;
