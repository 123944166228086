import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * Componente para proteger rutas que requieren autenticación
 * Si el usuario no está autenticado, lo redirige al login con la información
 * de la página a la que intentaba acceder
 */
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('token');

  if (!isAuthenticated) {
    // Redirigir al login y pasar la ubicación actual como state
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default ProtectedRoute;
