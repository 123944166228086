import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import { motion } from 'framer-motion';

const FavoriteItem = ({ favorite, onRemove, isActive, onActivate }) => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getItemType = () => {
    if (favorite.product) return 'product';
    if (favorite.company) return 'company';
    if (favorite.category) return 'category';
    return 'unknown';
  };

  const getItemName = () => {
    if (favorite.product_name) return favorite.product_name;
    if (favorite.company_name) return favorite.company_name;
    if (favorite.category_name) return favorite.category_name;
    return 'Elemento favorito';
  };

  // Función para cargar los datos del producto cuando sea necesario
  useEffect(() => {
    const fetchProductData = async () => {
      if (favorite.product) {
        setIsLoading(true);
        try {
          const response = await fetch(`https://findout-adf55aa841e8.herokuapp.com/api/products/${favorite.product}/`);
          if (response.ok) {
            const data = await response.json();
            setProductData(data);
          }
        } catch (error) {
          console.error('Error al obtener datos del producto:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchProductData();
  }, [favorite.product]);

  // Función para renderizar la imagen o el ícono según corresponda
  const renderItemImage = () => {
    const type = getItemType();
    
    // Si es un producto y tenemos datos, mostramos la imagen
    if (type === 'product' && productData && productData.image_url) {
      return (
        <div className="w-12 h-12 mr-2 overflow-hidden rounded-md flex-shrink-0">
          <img 
            src={productData.image_url} 
            alt={productData.name || getItemName()} 
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><rect x='3' y='3' width='18' height='18' rx='2' ry='2'></rect><circle cx='8.5' cy='8.5' r='1.5'></circle><polyline points='21 15 16 10 5 21'></polyline></svg>";
            }}
          />
        </div>
      );
    }
    
    // Para los otros tipos, usamos los íconos existentes
    let icon = '❤️';
    switch (type) {
      case 'product':
        icon = '🛍️';
        break;
      case 'company':
        icon = '🏪';
        break;
      case 'category':
        icon = '📂';
        break;
    }
    
    return <span className="text-2xl mr-2 flex-shrink-0 w-12 h-12 flex items-center justify-center">{icon}</span>;
  };

  const getItemTypeLabel = () => {
    const type = getItemType();
    switch (type) {
      case 'product':
        return 'Producto';
      case 'company':
        return 'Negocio';
      case 'category':
        return 'Categoría';
      default:
        return 'Favorito';
    }
  };

  const handleItemClick = () => {
    // Navegar a la página de la empresa correspondiente
    if (productData && productData.company) {
      navigate(`/company/${productData.company}`);
    } else if (favorite.company) {
      // Si el favorito es directamente una empresa
      navigate(`/company/${favorite.company}`);
    } else if (favorite.category) {
      // Si el favorito es una categoría
      navigate(`/company-categories/${favorite.category}`);
    }
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove(favorite.id);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className={`bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden border ${isActive ? 'border-primary' : 'border-gray-200 dark:border-gray-700'}`}
    >
      <div 
        className="p-4 cursor-pointer transition-colors hover:bg-gray-50 dark:hover:bg-gray-750"
        onClick={handleItemClick}
      >
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center">
            {isLoading ? (
              <div className="w-12 h-12 mr-2 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse flex-shrink-0"></div>
            ) : (
              renderItemImage()
            )}
            <div>
              <h3 className="font-medium text-gray-900 dark:text-white">{getItemName()}</h3>
              <span className="text-xs text-gray-500 dark:text-gray-400">
                {getItemTypeLabel()} • Guardado el {formatDate(favorite.created_at)}
              </span>
            </div>
          </div>
          <div className="flex space-x-2">
            <button 
              onClick={handleRemove}
              className="p-1.5 text-gray-500 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
              aria-label="Eliminar de favoritos"
            >
              <Trash2 size={18} />
            </button>
          </div>
        </div>

        {/* Mostramos las notas existentes si hay (mantenemos la visualización pero eliminamos la edición) */}
        {favorite.notes && (
          <div className="mt-2 p-2 bg-gray-50 dark:bg-gray-700/50 rounded text-sm text-gray-700 dark:text-gray-300">
            {favorite.notes}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default FavoriteItem;